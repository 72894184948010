<template>
  <div class="pl-2 py-3 bg-menu position-relative">
    <ul class="nav" v-if="userAuth.user.nuevo == 0">
      <li
        class="nav-item"
        :class="/perfil/.test($route.path) ? ' font-weight-bold' : ''"
      >
        <router-link
          :to="{ name: 'mi-perfil' }"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
          >Perfil</router-link
        >
      </li>
      <li
        class="nav-item"
        :class="/mi-evaluacion/.test($route.path) ? ' font-weight-bold' : ''"
      >
        <router-link
          :to="{ name: 'evaluacion' }"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
          >Mis Evaluaciones</router-link
        >
      </li>
      <li class="nav-item">
        <a
          :href="userAuth.user.url_dropbox"
          class="nav-link pb-0 opciones mb-2"
          target="_blank"
          >Mis Recursos</a
        >
      </li>
    </ul>
    <ul class="nav" v-else>
      <li
        class="nav-item"
        :class="
          /perfil/.test($route.path) ? 'opcion-activo font-weight-bold' : ''
        "
      >
        <p class="nav-link pb-0 opciones mb-2 cursor-pointer">Perfil</p>
      </li>
      <li
        class="nav-item"
        :class="
          /mi-evaluacion/.test($route.path)
            ? 'opcion-activo font-weight-bold'
            : ''
        "
      >
        <p class="nav-link pb-0 opciones mb-2 cursor-pointer">
          Mis Evaluaciones
        </p>
      </li>
      <li class="nav-item">
        <p class="nav-link pb-0 opciones mb-2 cursor-pointer">Mis Recursos</p>
      </li>
    </ul>
    <ul class="nav cerrar-session">
      <li class="nav-item">
        <p class="nav-link pb-0 opciones mb-2 cursor-pointer" @click="cerrar">
          Cerrar sesión
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "OpcionesCliente",
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
  },
  methods: {
    volver() {
      this.$router.go(-1);
    },
    cerrar() {
      this.$store.commit("setEstatusLoadingSession");
      this.$store.dispatch("logout").then((response) => {
        if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
          localStorage.removeItem("usuario");
          this.$store.commit("setEstatusLoadingSession");
          this.$router.push("/");
        } else {
          this.$store.commit("setEstatusLoadingSession");
        }
      });
    },
  },
};
</script>
<style lang="css">
.bg-menu {
  background-color: #f8f8f8;
}
.cerrar-session {
  position: absolute;
  right: 50px;
  top: 17px;
}
@media (max-width: 600px) {
  .cerrar-session {
    position: relative;
    display: block;
    right: 0;
    top: -10px;
  }
}
</style>