import http from '@/api'
import _ from 'lodash'

const actions = {
    setLogin: ({
        commit
    }, payload) => {
        return http.post('/login', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setUserAuth", response.DATA);
                localStorage.setItem("usuario", btoa(JSON.stringify(response.DATA)));
                return response;
            }
            return response;
        });
    },
    getAuth: ({
        commit
    }, payload) => {
        return http
            .post("/me", {
                token: payload
            })
            .then(resp => {
                if (_.has(resp, 'STATUS_CODE') && resp.STATUS_CODE == 1) {
                    if (localStorage.getItem("usuario")) {
                        let user = JSON.parse(atob(localStorage.getItem("usuario")))
                        user.user = resp.DATA
                        localStorage.setItem("usuario", btoa(JSON.stringify(user)));
                    }
                    commit('updateUserAuth', resp.DATA);
                }
                return resp;
            });
    },
    authUser: ({
        commit
    }) => {
        if (localStorage.getItem("usuario")) {
            commit("setUserAuth", JSON.parse(atob(localStorage.getItem("usuario"))));
        }
    },
    refreshUser: ({
        commit
    }, payload) => {
        http.post("/refresh", payload).then(resp => {
            if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
                commit("setUserAuth", resp.DATA);
                localStorage.setItem("usuario", btoa(JSON.stringify(resp.DATA)));
                localStorage.setItem("count", 0);
            }
        });
    },
    logout: ({
        commit
    }) => {
        return http.post("/logout").then(resp => {
            return resp;
        });
    },
    sendEmail: ({
        commit
    }, payload) => {
        return http.post("/sendEmail", payload).then(resp => {
            return resp;
        });
    },
    verificarToken: ({
        commit
    }, payload) => {
        return http.post("/verificarToken", payload).then(resp => {
            return resp;
        });
    },
    updatePassUser: ({
        commit,
        getters
    }, payload) => {
        return http.post("/updatePass/" + getters.getUserAuth.user.id, payload).then(resp => {
            return resp;
        });
    },
    updatePassword: ({
        commit,
    }, payload) => {
        return http.post("/updatePassword", payload).then(resp => {
            return resp;
        });
    }
}

export default actions;