const mutations = {
    setTalleres(state, payload) {
        state.talleres = payload;
    },
    setEvaluacion(state, payload) {
        state.evalauacion = payload;
    },
    setListVideo(state, payload) {
        state.list_videos = payload;
    },
    setRecomendaciones(state, payload) {
        state.recomendaciones = payload
    }
}

export default mutations;