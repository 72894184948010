<template>
  <section>
    <loadingCerrar v-if="estadoCerrar" />
    <div class="py-3 pl-3 bg-white">
      <div class="row m-0 p-0">
        <div class="col-12 col-lg-6 col-xl-6 p-0">
          <img
            src="./../assets/img/logo-cerforc.svg"
            class="img-fluid"
            style="height: 60px !important"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-6 p-0 text-right pr-3">
          <img
            src="./../assets/img/logo-holistica.svg"
            class="img-fluid"
            style="height: 60px !important"
          />
        </div>
      </div>
    </div>
    <opciones />
  </section>
</template>
<script>
import loadingCerrar from "@/components/loadingSession.vue";
import opciones from "@/components/MenuCliente";
export default {
  name: "layout-cliente",
  components: {
    opciones,
    loadingCerrar,
  },
  computed: {
    estadoCerrar() {
      return this.$store.getters.getEstatusLoadingSession;
    },
  },
};
</script>