import http from '@/api';
import _ from 'lodash';

const actions = {
    fetchTalleresCliente: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        http.get('/tallerCliente/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit('setTalleres', response.DATA);
            }
            commit("setEstatusLoading")
        });
    },
    fetchEvaluacionCliente: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/evaluacionCliente', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit('setEvaluacion', response.DATA);
            }
            commit("setEstatusLoading")
            return response;
        });
    },
    updateCliente: ({
        commit
    }, payload) => {
        return http.post('/updateMiDate', payload).then((response) => {
            return response;
        });
    },
    fetchGrafica: ({ commit }, payload) => {
        return http.post('/graficaCliente', payload).then((response) => {
            return response
        })
    },
    fetchClienteVideo: ({
        commit
    }, payload) => {
        return http.get('/listVideo/' + payload.taller_id + '/' + payload.user_id).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit('setListVideo', response.DATA);
            }
        });
    },
    fetchClienteRecomendacion: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/listRecomendaciones/' + payload.taller_id + '/' + payload.user_id).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit('setRecomendaciones', response.DATA);
            }
            commit("setEstatusLoading")
        });
    },
    uploadFileCliente: ({
        commit
    }, payload) => {
        return http.post('/uploadFotoCliente', payload).then((response) => {
            return response;
        });
    }
}

export default actions;