import Vue from 'vue'
import axios from 'axios'
import vueAxios from 'vue-axios'
import _ from 'lodash';
import AxiosPlugin from 'vue-axios-cors';

const apiAxios = axios.create({
    headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    }
});
apiAxios.interceptors.request.use(
    config => {
        if (localStorage.getItem("usuario")) {
            let token = JSON.parse(atob(localStorage.getItem("usuario")))
            config.headers['Authorization'] = 'Bearer ' + token.access_token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });
Vue.use(vueAxios, apiAxios, AxiosPlugin);

const api = {
    get(url, request) {
        const datos = this.get_data(request);
        return Vue.axios
            .get(process.env.VUE_APP_WEBSERVICE_URL + url + datos)
            .then(response => {
                if (!_.has(response.data, 'STATUS_CODE')) {
                    console.error('Error de conexión')
                }

                return Promise.resolve(response.data)
            })

    },
    post(url, request) {
        request = this.post_data(request);
        return Vue.axios
            .post(process.env.VUE_APP_WEBSERVICE_URL + url, request)
            .then(response => {
                if (!_.has(response.data, 'STATUS_CODE')) {
                    console.error('Error de conexión')
                }

                return Promise.resolve(response.data)
            }).catch(error => {

                console.error('Error de conexión');
                return Promise.reject(error.response.data)
            })
    },
    put(url, request) {
        request = this.post_data(request);
        return Vue.axios
            .put(process.env.VUE_APP_WEBSERVICE_URL + url, request)
            .then(response => {
                if (!_.has(response.data, 'STATUS_CODE')) {
                    console.error('Error de conexión')
                }
                return Promise.resolve(response.data)
            })
            .catch(error => {

                console.error('Error de conexión');
                return Promise.reject(error.response.data)
            })
    },
    download(url, request) {
        request = this.post_data(request);
        return Vue.axios
            .post(process.env.VUE_APP_WEBSERVICE_URL + url, request, {
                responseType: 'arraybuffer'
            })
            .then(response => {
                if (!_.has(response.data, 'STATUS_CODE')) {
                    console.error('Error de conexión')
                }

                return Promise.resolve(response)
            })
            .catch(error => {

                console.error('Error de conexión');
                return Promise.reject(error.response.data)
            })
    },
    patch(url, request) {
        request = this.post_data(request);
        return Vue.axios
            .patch(process.env.VUE_APP_WEBSERVICE_URL + url, request)
            .then(response => {
                if (!_.has(response.data, 'STATUS_CODE')) {
                    console.error('Error de conexión')
                }

                return Promise.resolve(response.data)
            })
            .catch(error => {

                console.error('Error de conexión');
                return Promise.reject(error.response.data)
            })
    },
    delete(url, request) {
        // request=this.post_data(request);
        return Vue.axios
            .delete(process.env.VUE_APP_WEBSERVICE_URL + url, {
                params: request
            })
            .then(response => {
                if (!_.has(response.data, 'STATUS_CODE')) {
                    console.error('Error de conexión')
                }

                return Promise.resolve(response.data)
            })
            .catch(error => {

                console.error('Error de conexión');
                return Promise.reject(error.response.data)
            })
    },
    post_data(data) {
        const formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        return formData;
    },
    get_data(data) {
        let contador = 0;
        let datos = "";
        for (var key in data) {
            datos += (contador == 0) ? "?" : "&"
            datos += key + "=" + data[key]
            contador++
        }
        return datos;
    }
}
export default api;