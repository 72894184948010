const mutations = {
    setCiudades(state, payload) {
        state.ciudades = payload;
    },
    setEmpresas(state, payload) {
        state.empresas = payload;
    },
    setTipoTalleres(state, payload) {
        state.tipoTalleres = payload;
    },
    setFormadores(state, payload) {
        state.formadores = payload;
    },
    resetValues(state) {
        state.ciudades = [];
        state.empresas = [];

    }
}

export default mutations;