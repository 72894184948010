import http from '@/api'
import _ from 'lodash'

const actions = {
    fetchFormadores: ({
        commit
    }) => {
        commit("setEstatusLoading")
        http.get('/listFormadores').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setFormadores", response.DATA);
                return true;
            }
            commit("setEstatusLoading")
        });
    },
    fetchEvaluadores: ({
        commit
    }) => {
        commit("setEstatusLoading")
        http.get('/listEvaluadores').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setEvaluadores", response.DATA);
                return true;
            }
            commit("setEstatusLoading")
        });
    },
    setFormador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/formador', payload).then((response) => {
            commit("setEstatusLoading")
            return response
        });
    },
    setEvaluador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/evaluador', payload).then((response) => {
            commit("setEstatusLoading")
            return response
        });
    },
    fetchFormador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/detalleFormador/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    fetchEvaluador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/detalleEvaluador/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    updateFormador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/updateFormador/' + payload.id + '/update', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    updateEvaluador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/updateEvaluador/' + payload.id + '/update', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    uploadFileEmpleado: ({
        commit
    }, payload) => {
        return http.post('/uploadFoto', payload).then((response) => {
            return response;
        });
    }
}

export default actions;