const talleres = {
    talleres: [],
    evaluacion: {},
    resultados: [],
    usuarioEvaluar: {},
    ranking: [],
    plantillas: [],
    info: [],
    consulta: [],
    detalle_plantilla: {},
    rankings: []
}

export default talleres;