import Vue from 'vue'
import Vuex from 'vuex'
// UI
import UiStore from './ui/store.ui'
import UiMutations from './ui/mutations.ui'
import UiGetters from './ui/getters.ui'
//AUTH
import AuthStore from './auth/store.auth'
import AuthMutations from './auth/mutations.auth'
import AuthGetters from './auth/getters.auth'
import AuthActions from './auth/actions.auth'
// INDEPENDIENTES
import IndependienteStore from './Independiente/store.independiente'
import IndependienteMutations from './Independiente/mutations.independiente'
import IndependienteGetters from './Independiente/getters.independiente'
import IndependienteActions from './Independiente/actions.independiente'
// TALLER
import tallerStore from './Taller/store.taller'
import tallerMutations from './Taller/mutations.taller'
import tallerGetters from './Taller/getters.taller'
import tallerActions from './Taller/actions.taller'
// EMPLEADOS
import empleadoStore from './Empleado/store.empleado'
import empleadoMutations from './Empleado/mutations.empleado'
import empleadoGetters from './Empleado/getters.empleado'
import empleadoActions from './Empleado/actions.empleado'
//CALIFICADOR
import calificadorStore from './calificador/store.calificador'
import calificadorMutations from './calificador/mutations.calificador'
import calificadorGetters from './calificador/getters.calificador'
import calificadorActions from './calificador/actions.calificador'
//CLIENTE
import clienteStore from './cliente/store.cliente'
import clienteMutations from './cliente/mutations.cliente'
import clienteGetters from './cliente/getters.cliente'
import clienteActions from './cliente/actions.cliente'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login: {
      state: AuthStore,
      mutations: AuthMutations,
      getters: AuthGetters,
      actions: AuthActions
    },
    ui: {
      state: UiStore,
      mutations: UiMutations,
      getters: UiGetters
    },
    independiente: {
      state: IndependienteStore,
      mutations: IndependienteMutations,
      getters: IndependienteGetters,
      actions: IndependienteActions
    },
    taller: {
      state: tallerStore,
      mutations: tallerMutations,
      getters: tallerGetters,
      actions: tallerActions
    },
    empleado: {
      state: empleadoStore,
      mutations: empleadoMutations,
      getters: empleadoGetters,
      actions: empleadoActions
    },
    calificador: {
      state: calificadorStore,
      mutations: calificadorMutations,
      getters: calificadorGetters,
      actions: calificadorActions
    },
    cliente: {
      state: clienteStore,
      mutations: clienteMutations,
      getters: clienteGetters,
      actions: clienteActions
    }
  }
})