import http from '@/api'
import _ from 'lodash';

const actions = {
    fetchTalleres: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        http.get('/listTaller').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setTalleres", response.DATA);
            }
            commit("setEstatusLoading")
        });
    },
    fetchPlantillas: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        return http.get('/listarPlantillas').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setPlantillas", response.DATA);
                return response
            }
            commit("setEstatusLoading")
        });
    },
    fetchInfo: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        http.get('/listInfo').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setInfo", response.DATA);
            }
            commit("setEstatusLoading")
        });
    },
    fetchInfoModal: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        return http.get('/listInfo').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setInfo", response.DATA);
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    setTaller: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/crearTaller', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    setAspecto: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/guardar_aspecto', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    setSubAspecto: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/guardar_subaspecto', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    fectEvaluacion: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/evaluacion/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEvaluacion", response.DATA);
            }
            commit("setEstatusLoading")
            return response;
        });
    },
    setResultado: ({
        commit
    }, payload) => {
        return http.post('/resultados', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                return response;
            }
        });
    },
    fetchResultado: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/resultado/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setResultados", response.DATA);
            }
            commit("setEstatusLoading")
            return response;
        });
    },
    fetchFormadoresTaller: ({
        commit
    }) => {
        commit("setEstatusLoading")
        return http.get('/listFormadores').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setFormadores", response.DATA);
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    fetchTaller: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/detalleTaller/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    },
    updateTaller: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/updateTaller/' + payload.id + '/update', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")

        })
    },
    updateAspecto: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/' + payload.id + '/actualizar_aspecto', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    },
    updateSubAspecto: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/' + payload.id + '/actualizar_subaspecto', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    },
    deleteTaller: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.delete('/deleteTaller/' + payload + '/delete').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")

        })
    },
    setChartFile: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/uploadFileChart', payload).then((response) => {
            commit("setEstatusLoading")
            return response;
        });
    },
    deleteArchivos: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/uploadFileChartDelete', payload).then((response) => {
            commit("setEstatusLoading")
            return response;
        });
    },
    fetchGenerar: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/informe/' + payload.taller_id + '/' + payload.user_id + '/' + payload.opcion + '/generar').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    cambio: ({
        commit
    }, payload) => {
        http.post('/cambio', payload);
    },
    updateResultado: ({
        commit
    }, payload) => {
        return http.post('/actualizarResultados/' + payload.id + '/update', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                return response;
            }
        });
    },
    cargarVideo: ({
        commit
    }, payload) => {
        return http.post('/uploadVideoCliente', payload).then((response) => {
            return response;
        });
    },
    cambiarEstado: ({
        commit
    }, payload) => {
        return http.get('/cambio/' + payload).then((response) => {
            return response;
        });
    },
    fetchRanking: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/ranking/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit('setRanking', response.DATA);
            }
            commit("setEstatusLoading")
            return response;
        });
    },
    duplicar: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/duplicar', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    duplicarPlantilla: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/duplicar_plantilla', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    eliminarAspecto: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.delete(`/${payload}/eliminar_aspecto`).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    addSubAspecto: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/anadir_subaspecto', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    removeSubAspecto: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/eliminar_subaspecto', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    query: ({
        commit,
        getters
    }, payload) => {
        return http.get(`/${payload}/consulta`).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                response.DATA.aspecto.sub_aspectos.map((item) => {
                    item.eliminar = false
                    return item;
                });
                commit("setConsulta", response.DATA);
                return response
            }
        });
    },
    setPlantilla: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/guardar_plantilla', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    editPlantilla: ({
        commit,
    }, payload) => {
        commit("setEstatusLoading")
        return http.get(`/${payload}/detalle`).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                response.DATA.aspectos.map(item => {
                    item.eliminar = false
                    return item
                })
                commit("setEstatusLoading")
                commit("setDetallePlantilla", response.DATA);
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    updatePlantilla: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/' + payload.id + '/actualizar_plantilla', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    eliminarPlantilla: ({
        commit,
        getters
    }, payload) => {
        commit("setEstatusLoading")
        return http.delete(`/${payload}/eliminar_plantilla`).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    genEvaFor: ({
        commit,
        getters
    }, payload) => {
        return http.post('/generarEvaluacion', payload).then((response) => {
            return response;
        });
    },
}
export default actions;