<template>
  <div>
    <encabezado />
    <opciones />
  </div>
</template>
<script>
import encabezado from "@/components/Encabezado";
import opciones from "@/components/Menu";
export default {
  name: "layout",
  components: {
    encabezado,
    opciones,
  },
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
  },
};
</script>