import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Plataforma from './layouts/layout_plataforma.vue'
import LayoutDefault from './layouts/layout_default.vue'
import LayoutCliente from './layouts/layout_cliente.vue'
import modalEliminar from './components/ModalEliminar.vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
import api from "./api/index"
import VueImgOrientationChanger from 'vue-img-orientation-changer'

import {
  library
} from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faMapMarkedAlt,
  faCalendarAlt,
  faUserTie,
  faNetworkWired,
  faKey,
  faSignOutAlt,
  faVideo,
  faDownload,
  faPlusCircle,
  faPencilAlt,
  faTrashAlt,
  faCopy,
  faCog
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

library.add(
  faBuilding,
  faMapMarkedAlt,
  faCalendarAlt,
  faUserTie,
  faNetworkWired,
  faKey,
  faSignOutAlt,
  faVideo,
  faDownload,
  faPlusCircle,
  faPencilAlt,
  faTrashAlt,
  faCopy,
  faCog
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("plataforma-layout", Plataforma)
Vue.component("cliente-layout", LayoutCliente)
Vue.component("default-layout", LayoutDefault)
Vue.component("modal-eliminar", modalEliminar)
Vue.use(CKEditor);
Vue.use(VueImgOrientationChanger)

require("./assets/holisticaCss.css");
require("./assets/main.scss");

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  api,
  render: h => h(App)
}).$mount("#app");