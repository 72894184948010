<template>
  <div class="pl-2 contenedor-menu">
    <ul class="nav" v-if="!/view/.test($route.path)">
      <li
        class="nav-item"
        :class="/informes/.test($route.path)?'opcion-activo font-weight-bold':''"
        v-if="[1].includes(userAuth.user.rol_id)"
      >
        <router-link
          :to="{name:'informes'}"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
        >Informes</router-link>
      </li>
      <li
        class="nav-item"
        :class="/formadores/.test($route.path)?'opcion-activo font-weight-bold':''"
        v-if="[1,3].includes(userAuth.user.rol_id)"
      >
        <router-link
          :to="{name:'formadores'}"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
        >Formadores</router-link>
      </li>
      <li
        class="nav-item"
        :class="/talleres/.test($route.path)?'opcion-activo font-weight-bold':''"
        v-if="[1,3].includes(userAuth.user.rol_id)"
      >
        <router-link
          :to="{name:'talleres'}"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
        >Programación Talleres</router-link>
      </li>
      <li
        class="nav-item"
        :class="/evaluadores/.test($route.path)?'opcion-activo font-weight-bold':''"
        v-if="[1].includes(userAuth.user.rol_id)"
      >
        <router-link
          :to="{name:'evaluadores'}"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
        >Evaluadores</router-link>
      </li>
      <li
        class="nav-item"
        :class="/evaluaciones/.test($route.path)?'opcion-activo font-weight-bold':''"
        v-if="[1,3].includes(userAuth.user.rol_id)"
      >
        <router-link
          :to="{name:'evaluaciones'}"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
        >Evaluaciones</router-link>
      </li>
      <li
        class="nav-item"
        :class="/configuracion/.test($route.path)?'opcion-activo font-weight-bold':''"
      >
        <router-link
          :to="{name:'configuracion'}"
          class="nav-link pb-0 opciones mb-2 cursor-pointer"
        >Configuración</router-link>
      </li>
    </ul>
    <ul class="nav" v-else>
      <li class="nav-item">
        <p class="nav-link pb-0 opciones mb-2 cursor-pointer" @click="volver">Volver</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "opciones",
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
  },
  methods: {
    volver() {
      this.$router.go(-1);
    },
  },
};
</script>