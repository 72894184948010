import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
    routes: [{
        path: '/',
        name: 'login',
        meta: {
            layout: 'default'
        },
        component: () => import(/* webpackChunkName: "formadores" */ '../views/Auth/Login.vue')
    },
    {
        path: '/enviar_correo',
        name: 'enviarCorreo',
        meta: {
            layout: 'default'
        },
        component: () => import(/* webpackChunkName: "formadores" */ '../views/Auth/sendEmail')
    },
    {
        path: '/update_pass/verify/:token',
        name: 'updatePass',
        meta: {
            layout: 'default'
        },
        component: () => import(/* webpackChunkName: "formadores" */ '../views/Auth/updatePass')
    },
    {
        path: '/formadores',
        name: 'formadores',
        component: () => import(/* webpackChunkName: "formadores" */ '../views/plataforma/formadores/Formadores.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/formadores/crear',
        name: 'crear.formador',
        component: () => import(/* webpackChunkName: "formadores" */ '../views/plataforma/formadores/CrearFormador.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/formadores/:id/editar',
        name: 'editar.formador',
        component: () => import(/* webpackChunkName: "formadores" */ '../views/plataforma/formadores/EditarFormador.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/talleres/proximos',
        name: 'talleres',
        component: () => import(/* webpackChunkName: "talleres" */ '../views/plataforma/talleres/Talleres.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/talleres/programar',
        name: 'crear.taller',
        component: () => import(/* webpackChunkName: "talleres crear" */ '../views/plataforma/talleres/CrearTaller.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/talleres/programar/:id/editar',
        name: 'editar.taller',
        component: () => import(/* webpackChunkName: "talleres crear" */ '../views/plataforma/talleres/EditarTaller.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/talleres/list-plantillas',
        name: 'plantillas',
        component: () => import(/* webpackChunkName: "plantillas" */ '../views/plataforma/talleres/Plantillas.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/talleres/plantilla-taller',
        name: 'crear.plantilla',
        component: () => import(/* webpackChunkName: "plantillas crear" */ '../views/plataforma/talleres/CrearPlantilla.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/talleres/plantilla/:id/plantilla-taller',
        name: 'editar.plantilla',
        component: () => import(/* webpackChunkName: "plantillas crear" */ '../views/plataforma/talleres/EditarPlantilla.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/informes',
        name: 'informes',
        component: () => import(/* webpackChunkName: "informes" */ '../views/plataforma/informes/Informes.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/evaluadores',
        name: 'evaluadores',
        component: () => import(/* webpackChunkName: "evaluadores" */ '../views/plataforma/evaluadores/Evaluadores.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/evaluadores/crear',
        name: 'crear.evaluador',
        component: () => import(/* webpackChunkName: "evaluadores" */ '../views/plataforma/evaluadores/CrearEvaluador.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/evaluadores/:id/editar',
        name: 'editar.evaluador',
        component: () => import(/* webpackChunkName: "evaluadores" */ '../views/plataforma/evaluadores/EditarEvaluador.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/evaluaciones',
        name: 'evaluaciones',
        component: () => import(/* webpackChunkName: "evaluaciones" */ '../views/plataforma/evaluaciones/Evaluaciones.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/evaluaciones/:id/realizar',
        name: 'realizar',
        component: () => import(/* webpackChunkName: "evaluaciones" */ '../views/plataforma/evaluaciones/Evaluar.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/configuracion',
        name: 'configuracion',
        component: () => import(/* webpackChunkName: "configuracion" */ '../views/plataforma/configuracion/Configuracion.vue'),
        meta: {
            layout: 'plataforma',
            requiresAuth: true
        }
    },
    {
        path: '/presentacion/view/:id',
        name: 'preview',
        component: () => import(/* webpackChunkName: "configuracion" */ '../views/plataforma/evaluaciones/Grafica.vue'),
        meta: {
            layout: 'default',
            requiresAuth: true
        }
    },
    {
        path: '/presentacion/view/:id/ranking',
        name: 'ranking',
        component: () => import(/* webpackChunkName: "configuracion" */ '../views/plataforma/evaluaciones/Ranking.vue'),
        meta: {
            layout: 'default',
            requiresAuth: true
        }
    },
    {
        path: '/cliente/perfil',
        name: 'mi-perfil',
        component: () => import(/* webpackChunkName: "cliente" */ '../views/cliente/items/Perfil.vue'),
        meta: {
            layout: 'cliente',
            requiresAuth: true
        },
    },
    {
        path: '/cliente/mi-evaluacion',
        name: 'evaluacion',
        component: () => import(/* webpackChunkName: "cliente" */ '../views/cliente/items/MiEvaluacion.vue'),
        meta: {
            layout: 'cliente',
            requiresAuth: true
        },
    },
    {
        path: '/cliente/evaluar/vocero',
        name: 'vocero',
        component: () => import(/* webpackChunkName: "calificacion-vocero" */ '../views/cliente/Vocero.vue'),
        meta: {
            layout: 'default',
            requiresAuth: true
        }
    },
    {
        path: '*',
        redirect: '/'
    }, {
        path: '/',
        redirect: '/'
    }
    ]
})

router.beforeEach((to, from, next) => {
    let auth_storage = localStorage.getItem('usuario') != undefined ? JSON.parse(atob(localStorage.getItem('usuario'))) : false;
    let usuario = auth_storage ? store.dispatch("getAuth", auth_storage.access_token).then(resp => {
        if (resp.STATUS_CODE == 1) {
            return true;
        } else {
            return false;
        }

    }) : false;
    let autorizacion = to.matched.some(record => record.meta.requiresAuth);
    if (autorizacion && !usuario) {
        next('/');
    }
    else {
        next();
    }
})


export default router;