import http from '@/api';
import _ from 'lodash';

const actions = {
    fetchNotificador: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.get('/notificador/' + payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit('setCalificador', response.DATA);
            }
            commit("setEstatusLoading")
            return response
        })
    },
    setCalificacion: ({
        commit
    }, payload) => {
        commit("setEstatusLoading")
        return http.post('/calificacion', payload).then((response) => {
            commit("setEstatusLoading")
            return response
        })
    }
}

export default actions;