const getters = {
    getTalleres: (state) => state.talleres,
    getEvaluacion: (state) => state.evaluacion,
    getResultados: (state) => state.resultados,
    getUsuarioEvaluar: (state) => state.usuarioEvaluar,
    getRanking: (state) => state.ranking,
    getPlantillas: (state) => state.plantillas,
    getInfo: (state) => state.info,
    getConsulta: (state) => state.consulta,
    getDetallePlantilla: (state) => state.detalle_plantilla,
    getRankings: (state) => state.rankings

}

export default getters;