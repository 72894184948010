<template>
  <div class="row m-0">
    <div class="col-4 col-lg-2 my-2 contenedor-logo">
      <img src="./../assets/img/logo-cerforc.svg" class="logo" />
    </div>
    <div
      class="align-items-center col-8 col-lg-8 d-flex overflow-hidden p-0 pl-2"
    >
      <div
        class="content-header fondo-header align-items-center content-header d-flex fondo-header"
      >
        <h4 class="m-0 text-white pl-3">
          ¡Hola, bienvenido {{ $store.getters.getUserAuth.user.nombre }}
          {{ $store.getters.getUserAuth.user.apellido }}!
        </h4>
      </div>
    </div>
    <div class="col-4 col-lg-2 my-2 contenedor-logo" style="border: none">
      <img src="./../assets/img/logo-holistica.svg" class="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>
<style lang="css">
.fondo-header {
  width: 100%;
  height: 77%;
  background: rgb(255, 104, 49);
  background: linear-gradient(
    90deg,
    rgba(255, 104, 49, 1) 0%,
    rgba(0, 31, 74, 1) 100%
  );
}
</style>
