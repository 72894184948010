import http from '@/api'
import _ from 'lodash'

const actions = {
    fetchEmpresas: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        return http.get('/empresas').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setEmpresas", response.DATA);
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    fetchCiudades: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        return http.get('/ciudades').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                commit("setCiudades", response.DATA);
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    fetchTipoTalleres: ({
        commit,
        getters
    }) => {
        commit("setEstatusLoading")
        return http.get('/tipoTalleres').then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setTipoTalleres", response.DATA);
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        });
    },
    setCiudad: ({ commit, getters }, payload) => {
        commit("setEstatusLoading")
        return http.post('/guardar_ciudades', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    },
    updateCiudad: ({ commit, getter }, payload) => {
        commit("setEstatusLoading")
        return http.post(`/${payload.id}/actualizar_ciudades`, payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    },
    setEmpresa: ({ commit, getter }, payload) => {
        commit("setEstatusLoading")
        return http.post('/guardar_empresa', payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    },
    updateEmpresa: ({ commit, getter }, payload) => {
        commit("setEstatusLoading")
        return http.post(`/${payload.id}/actualizar_empresa`, payload).then((response) => {
            if (_.has(response, 'STATUS_CODE') && response.STATUS_CODE == 1) {
                commit("setEstatusLoading")
                return response;
            }
            commit("setEstatusLoading")
        })
    }
}

export default actions;