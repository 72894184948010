<template>
  <section>
    <component :is="layout" />
    <router-view />
  </section>
</template>

<script>
const defaultLayout = "default";
export default {
  name: "app",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
  },
  beforeCreate() {
    this.$store.dispatch("authUser");
    var checkCloseX = 0;
  },
};
</script>
