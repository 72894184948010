const mutations = {
    setTalleres(state, payload) {
        state.talleres = payload;
    },
    setEvaluacion(state, payload) {
        state.evaluacion = payload;
    },
    setResultados(state, payload) {
        state.resultados = payload;
    },
    addResultado(state, payload) {
        let valid = 0;
        for (const value of state.resultados) {
            if (value.fase == payload.fase && value.subappearance_id == payload.subappearance_id && value.user_id == payload.user_id) {
                valid++
            }
        }

        if (valid == 0) {
            state.resultados.push(payload);
        }
    },
    addResultadoNuevoi(state, payload) {
        let valid = 0;
        for (const value of state.resultados) {
            if (value.user_id == payload.user_id && value.appearence_id == payload.appearence_id) {
                valid++
            }
        }

        if (valid == 0) {
            state.resultados.push(payload);
        }
    },
    setUsuarioEvaluar(state, payload) {
        state.usuarioEvaluar = payload;
    },
    setRanking(state, payload) {
        state.ranking = payload;
    },
    setPlantillas(state, payload) {
        state.plantillas = payload;
    },
    setInfo(state, payload) {
        payload.map(item => item.ver_definicion = false)
        state.info = payload;
    },
    setConsulta(state, payload) {
        state.consulta = payload
    },
    setDetallePlantilla(state, payload) {
        state.detalle_plantilla = payload
    },
    setRanking(state, payload) {
        if (payload != undefined) {
            payload.info.sort(function (a, b) {
                return a.puntaje > b.puntaje ? -1 : 1;
            });
        }
        state.rankings = payload
    }
}

export default mutations;