<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalEliminar">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confrimar eliminación</h5>
          </div>
          <div class="modal-body text-center">¿Estás seguro de que desea eliminar el registro?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="open"
              :disabled="estado"
            >Cancelar</button>
            <button type="button" class="btn btn-danger" @click="eliminar" v-if="!estado">Eliminar</button>
            <button class="btn btn-danger" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Eliminando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      estado: false,
    };
  },
  methods: {
    open() {
      this.estado = false;
      $("#modalEliminar").modal("toggle");
    },
    eliminar() {
      this.estado = true;
      this.$emit("eliminar");
    },
  },
};
</script>