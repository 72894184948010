const mutations = {
    setPosicion(state, payload) {
        state.posicion = payload;
    },
    setEstatusLoading(state) {
        state.cargando = !state.cargando;
    },
    setEstatusLoadingSession(state) {
        state.cerrar_session = !state.cerrar_session;
    }
}

export default mutations;